import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, remove, query, orderByChild, equalTo } from "firebase/database";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackbarWrapper from "../../components/Snackbar/SnackBarWrapper";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import MuiAlert from '@mui/material/Alert';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import { idCuadrillaNucleo, idCuadrillaBodega } from '../../utils/default'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ListCuadrillas() {
    const [cuadrillas, setCuadrillas] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [cuadrillaToDelete, setCuadrillaToDelete] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [precioFilter, setPrecioFilter] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');

    useEffect(() => {
        onValue(ref(database, '/cuadrillas/'), (snapshot) => {
            onDataChange(Object.values(snapshot.val()))
        }, {
            onlyOnce: true
        });
    }, []);

    const onDataChange = (items) => {
        setCuadrillas(items);
    }

    const handleClose = () => {
        setOpenDeleteModal(false)
    }

    const deleteCuadrilla = () => {
        remove(ref(database, 'cuadrillas/' + cuadrillaToDelete))
            .then(() => {
                setSnackbarInfo({ text: 'Eliminado correctamente!', type: 'success', open: true })
                setOpenDeleteModal(false);
                setTimeout(() => {
                    window.location.reload();
                  }, 2500);
            })
            .catch((error) => {
                setOpenDeleteModal(false)
                setSnackbarInfo({ text: 'Ocurrió un error', type: 'error', open: true })
            });
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const displayTable = searchTitle !== "" ? precioFilter : cuadrillas;

    return (
        <Container component="main" maxWidth="xl">
            <Button variant="contained" href="/add-cuadrilla">Nueva</Button>
            <h4>Listado de cuadrillas</h4>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Código</StyledTableCell>
                            {/* <StyledTableCell>Seriales</StyledTableCell> */}
                            <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cuadrillas &&
                            displayTable.map((cuadrilla, index) => (
                                (cuadrilla.id !== idCuadrillaNucleo && cuadrilla.id !== idCuadrillaBodega) &&
                                <StyledTableRow
                                    key={index}
                                    sx={cuadrillas?.deleted && { display: 'none' }}
                                >
                                    <StyledTableCell>{cuadrilla.id}</StyledTableCell>
                                    <StyledTableCell>{cuadrilla.numero}</StyledTableCell>
                                    {/* <StyledTableCell>{cuadrilla?.seriales && cuadrilla.seriales.map(serial => (
                                        <div>
                                            <span>{serial.tipo.toUpperCase()} : </span>
                                            <span> {serial.id}</span>
                                        </div>
                                    ))
                                    }
                                    </StyledTableCell> */}

                                    <StyledTableCell className="column-actions">
                                        <IconButton
                                            aria-label="delete"
                                            className="action__link"
                                            href={`/cuadrilla/${cuadrilla.id}`}
                                            role="button"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            type="button"
                                            className="action__button"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setCuadrillaToDelete(cuadrilla.id)
                                            }}
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Desea eliminar esta cuadrilla?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Los cambios serán permanentes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={deleteCuadrilla} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ListCuadrillas;
