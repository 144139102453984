import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, set, update, equalTo, runTransaction } from "firebase/database";
import OutlinedInput from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { idCuadrillaBodega } from '../../utils/default';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputSerials from "../../components/InputSerials/InputSerials";
import { materialsValidate, servicesId } from "../../utils/validations";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddMaterials({ nextStep, data, onSave }) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    let { id } = useParams();
    const cuadrillaSelected = { id: data?.idCuadrilla, numero: data?.cuadrilla };
    const isMudanza = data?.tipoOt === 'Mudanza externa';

    const [state, setState] = useState({
        idOt: id,
        materials: [],
        materialsToSave: [],
        serialOnt: [],
        serialesFlow: [],
        ontRetirado: [],
        decoRetirado: [],
        controles: [],
        serialesConfigurados: {},
        ontBodega: [],
        flowBodega: [],
        serialesToSave: [],
        comments: '',
        submitted: false,
    })
    const [bodega, setBodega] = useState([]);
    const [cuadrilla, setCuadrilla] = useState([]);
    const [serialConfigList, setSerialConfigList] = useState([
        <TextField
            id="outlined-number"
            label="Serial equipo configurado"
            className="text-form__serial"
            onChange={(e) => onChangeValues(e, 0)}
            InputLabelProps={{
                shrink: true,
            }}
            sx={{ marginBottom: '20px', marginRight: '10px' }}
        />
    ]);
    const [materialsCuadrilla, setMaterialsCuadrilla] = useState([]);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [isOnSave, setIsOnSave] = useState(false);
    const [alertValidationAdaptadorMecanico, setAlertValidationAdaptadorMecanico] = useState('');
    const [alertValidationRoseta, setAlertValidationRoseta] = useState('');
    const [alertValidationConectorSc, setAlertValidationConectorSc] = useState('');
    const [alertValidationRetenedor, setAlertValidationRetenedor] = useState('');
    const [alertValidationDropExterno, setAlertValidationDropExterno] = useState('');
    const [alertValidationCintas, setAlertValidationCintas] = useState('');
    const [alertValidationPreco50, setAlertValidationPreco50] = useState('');
    const [alertValidationPreco100, setAlertValidationPreco100] = useState('');
    const [alertValidationPreco150, setAlertValidationPreco150] = useState('');
    const [alertValidationPreco220, setAlertValidationPreco220] = useState('');
    const [alertValidationPreco300, setAlertValidationPreco300] = useState('');
    const [alertValidationSerialOnt, setAlertValidationSerialOnt] = useState('');
    const [alertValidationSerialFlow, setAlertValidationSerialFlow] = useState('');

    useEffect(() => {
        onValue(ref(database, '/materiales/'), (snapshot) => {
            setState(prevState => ({ ...prevState, materials: snapshot.val() }));
            countDigits(data?.contrato, snapshot.val())
        }, {
            onlyOnce: true
        });

        const cuadrilla = query(ref(database, 'materialsxcuadrilla'), orderByChild('idCuadrilla'), equalTo(parseInt(cuadrillaSelected.id, 10)));
        onValue(cuadrilla, (snapshot) => {
            if (snapshot.val()) {
                setMaterialsCuadrilla(Object.values(snapshot.val()))
            } else {
                setMaterialsCuadrilla([]);
            }
        }, {
            onlyOnce: true
        });

        const bodega = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(idCuadrillaBodega));
        onValue(bodega, (snapshot) => {
            setBodega(Object.values(snapshot.val())[0])
        }, {
            onlyOnce: true
        });

        const cuadrillaQuery = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(parseInt(cuadrillaSelected.id, 10)));
        onValue(cuadrillaQuery, (snapshot) => {
            setCuadrilla(Object.values(snapshot.val())[0])
        }, {
            onlyOnce: true
        });

        // PARA EL EDIT
        // const orderQuery = query(ref(database, 'ordenes'), orderByChild('id'), equalTo(parseInt(id, 10)));
        // onValue(orderQuery, (snapshot) => {
        //     const order = Object.values(snapshot.val());
        //     console.log(order[0].materials);
        //     setState(prevState => 
        //     ({ ...prevState,
        //         materialsToSave: order[0]?.materials,
        //         serialOnt: order[0]?.serialOnt,
        //         serialesFlow: order[0]?.serialesFlow,
        //         ontRetirado: order[0]?.ontRetirado,
        //         decoRetirado: order[0]?.decoRetirado,
        //         controles: order[0]?.controles,
        //         serialesConfigurados: order[0]?.serialesConfigurados,
        //         comments: order[0]?.comments,
        //     }))
        // }, {
        //     onlyOnce: true
        // });

    }, []);

    const countDigits = (str, materials) => {
        const digitArray = str.split('');
        const digitCounts = {};

        digitArray.forEach(digit => {
            digitCounts[digit] = (digitCounts[digit] || 0) + 1;
        });

        let newData = [];
        const keytDigit = Object.keys(digitCounts)
        const initId = 27
        keytDigit?.forEach(digit => {
            const indexMaterial = initId + parseInt(digit, 10)
            const formatData = {
                id: materials[indexMaterial]?.id,
                name: materials[indexMaterial]?.name,
                value: digitCounts[digit],
            }
            newData.push(formatData);
            setState(prevState => ({ ...prevState, materialsToSave: newData }));
        })
    };

    const changeInput = (e, id, name) => {
        const formatData = { id, name, value: e.target.value }
        const filterService = state.materialsToSave.filter(service => service.id === id);
        let newData = [];
        const keys = Object.keys(state.materialsToSave);
        if (filterService.length > 0) {
            let materialNewArray = [...state.materialsToSave]
            const keyService = keys.filter(key => state.materialsToSave[key].id === id);
            let materialToEdit = { ...materialNewArray[keyService] };
            materialToEdit.value = e.target.value
            materialNewArray[keyService] = materialToEdit
            setState(prevState => ({ ...prevState, materialsToSave: materialNewArray }));
        } else {
            newData = [...state.materialsToSave, formatData]
            setState(prevState => ({ ...prevState, materialsToSave: newData }));
        }

        if (e.target.value === '') {
            let materialNewArray = [...state.materialsToSave]
            const keyService = keys.filter(key => state.materialsToSave[key].id === id);
            materialNewArray.splice(parseInt(keyService[0], 10), 1);
            setState(prevState => ({ ...prevState, materialsToSave: materialNewArray }));
        }
    }

    const onChangeValues = (e, idSerial) => {
        const value = e.target.value;
        let newData = Object.assign(state.serialesConfigurados, { [idSerial]: value })
        setState(prevState => ({
            ...prevState,
            serialesConfigurados: newData
        }));
    };

    const onChangeControles = (e, id, name) => {
        const formatData = { id, name, value: parseInt(e.target.value, 10) }
        const filterService = state.controles.filter(service => service.id === id);
        let newData = [];
        const keys = Object.keys(state.controles);
        if (filterService.length > 0) {
            let materialNewArray = [...state.controles]
            const keyService = keys.filter(key => state.controles[key].id === id);
            let materialToEdit = { ...materialNewArray[keyService] };
            materialToEdit.value = e.target.value
            materialNewArray[keyService] = materialToEdit
            setState(prevState => ({ ...prevState, controles: materialNewArray }));
        } else {
            newData = [...state.controles, formatData]
            setState(prevState => ({ ...prevState, controles: newData }));
        }
    };

    const onAddBtnClick = (e, type) => {
        e.preventDefault();
        if (type === 'serial') {
            setSerialConfigList(serialConfigList.concat(
                <TextField
                    id="outlined-number"
                    label="Serial equipo configurado"
                    key={serialConfigList.length}
                    onChange={(e) => onChangeValues(e, serialConfigList.length)}
                    className="text-form__serial"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginBottom: '20px', marginRight: '10px' }}
                />
            ));
        }
    };

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const onChangeSerialOnt = (event, serialsSelected) => {
        const serialOntSelected = serialsSelected ? [serialsSelected?.id] : [];
        setState(prevState =>
        ({
            ...prevState,
            serialOnt: serialOntSelected
        }));
    }

    const onChangeSeriales = (event, serialsSelected, name) => {
        const serialOntBodega = serialsSelected ? [serialsSelected?.id] : [];
        const serialToSave = name !== 'ontBodega' ? serialsSelected.map(serial => serial.id) : serialOntBodega;
        setState(prevState =>
        ({
            ...prevState,
            [name]: serialToSave
        }));
    }

    const setNewCuadrillas = () => {
        // Seriales usados
        const serialsToExclude = state.serialesFlow.concat(state.serialOnt)
        const indexSeriales = serialsToExclude.map(serial => (
            cuadrilla.seriales.findIndex((obj) => obj.id === serial)
        ))
        let newArrayCuadrilla = cuadrilla.seriales;
        newArrayCuadrilla = newArrayCuadrilla?.filter((value, index) => indexSeriales.indexOf(index) === -1);
        cuadrilla.seriales = newArrayCuadrilla || [];
        setCuadrilla(cuadrilla);

        // // Seriales retirados
        // const serialsRetiradosToExclude = state.ontRetirado.concat(state.decoRetirado)
        // const indexSerialesRetirados = serialsRetiradosToExclude.map(serial => (
        //     bodega.seriales.findIndex((obj) => obj.id === serial)
        // ))
        // let newArrayBodega = bodega.seriales;
        // newArrayBodega = newArrayBodega?.filter((value, index) => indexSerialesRetirados.indexOf(index) === -1);
        // bodega.seriales = newArrayBodega || [];
        // setBodega(bodega);

        //Controles
        let newArrayControlsEntrega = cuadrilla?.controles || [];

        state.controles.forEach(control => {
            const filterTipoControl = newArrayControlsEntrega.filter(cont => cont.id === control.id)
            let newControlSave = []
            const keys = Object.keys(newArrayControlsEntrega);
            if (filterTipoControl.length > 0) {
                let materialNewArray = [...newArrayControlsEntrega]
                const keyService = keys.filter(key => newArrayControlsEntrega[key].id === control.id);
                let materialToEdit = { ...materialNewArray[keyService] };
                materialToEdit.value -= control.value
                materialNewArray[keyService] = materialToEdit
                newArrayControlsEntrega = materialNewArray
                cuadrilla.controles = newArrayControlsEntrega
                setCuadrilla(cuadrilla);
            } else {
                const formatControl = {
                    id: control.id,
                    name: control.name,
                    value: -control.value
                }
                newControlSave = [...newArrayControlsEntrega, formatControl]
                cuadrilla.controles = newControlSave
                setCuadrilla(cuadrilla);
            }
        })

    }

    const onChangeComments = (e) => {
        setState(prevState => ({ ...prevState, comments: e.target.value }));
    }

    const validations = () => {
        let countErrors = 0
        const serviceSelected = data?.services[0]
        const preco50 = state.materialsToSave.filter(material => material.id === materialsValidate.PRECO_50.id)
        const preco100 = state.materialsToSave.filter(material => material.id === materialsValidate.PRECO_100.id)
        const preco150 = state.materialsToSave.filter(material => material.id === materialsValidate.PRECO_150.id)
        const preco220 = state.materialsToSave.filter(material => material.id === materialsValidate.PRECO_220.id)
        const preco300 = state.materialsToSave.filter(material => material.id === materialsValidate.PRECO_300.id)

        const conectorSc = state.materialsToSave.filter(material => material.id === materialsValidate.CONECTOR_SC.id)
        const dropExterno = state.materialsToSave.filter(material => material.id === materialsValidate.DROP_EXTERNO.id)
        const retenedor = state.materialsToSave.filter(material => material.id === materialsValidate.RETENEDOR.id)
        const adaptadorMecanico = state.materialsToSave.filter(material => material.id === materialsValidate.ADAPTADOR_MECANICO.id)
        const roseta = state.materialsToSave.filter(material => material.id === materialsValidate.ROSETA_OPTICA.id)
        const cintaMetalica = state.materialsToSave.filter(material => material.id === materialsValidate.CINTA_METALICA.id)
        const ojal = state.materialsToSave.filter(material => material.id === materialsValidate.OJAL.id)
        const hebilla = state.materialsToSave.filter(material => material.id === materialsValidate.HEBILLA.id)
        const utp = state.materialsToSave.filter(material => material.id === materialsValidate.UTP5E.id)
        const rj45 = state.materialsToSave.filter(material => material.id === materialsValidate.RJ45.id)

        if (preco50?.length > 0 && parseInt(preco50[0]?.value, 10) > materialsValidate.PRECO_50.maxQuantity) {
            setAlertValidationPreco50(`La cantidad en ${preco50[0]?.name} debe ser como máximo ${materialsValidate.PRECO_50.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationPreco50('')
        }
        if (preco100?.length > 0 && parseInt(preco100[0]?.value, 10) > materialsValidate.PRECO_100.maxQuantity) {
            setAlertValidationPreco100(`La cantidad en ${preco100[0]?.name} debe ser como máximo ${materialsValidate.PRECO_100.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationPreco100('')
        }
        if (preco150?.length > 0 && parseInt(preco150[0]?.value, 10) > materialsValidate.PRECO_150.maxQuantity) {
            setAlertValidationPreco150(`La cantidad en ${preco150[0]?.name} debe ser como máximo ${materialsValidate.PRECO_150.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationPreco150('')
        }
        if (preco220?.length > 0 && parseInt(preco220[0]?.value, 10) > materialsValidate.PRECO_220.maxQuantity) {
            setAlertValidationPreco220(`La cantidad en ${preco220[0]?.name} debe ser como máximo ${materialsValidate.PRECO_220.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationPreco220('')
        }
        if (preco300?.length > 0 && parseInt(preco300[0]?.value, 10) > materialsValidate.PRECO_300.maxQuantity) {
            setAlertValidationPreco300(`La cantidad en ${preco300[0]?.name} debe ser como máximo ${materialsValidate.PRECO_300.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationPreco300('')
        }
        if (dropExterno?.length > 0 &&
            (parseInt(dropExterno[0]?.value, 10) <= materialsValidate.DROP_EXTERNO.minQuantity ||
                parseInt(dropExterno[0]?.value, 10) > materialsValidate.DROP_EXTERNO.maxQuantity)
        ) {
            setAlertValidationDropExterno(
                `La cantidad en ${dropExterno[0]?.name} debe ser como mínimo ${materialsValidate.DROP_EXTERNO.minQuantity} y máximo ${materialsValidate.DROP_EXTERNO.maxQuantity}`
            )
            countErrors += 1;
        } else {
            setAlertValidationDropExterno('')
        }
        if (retenedor?.length > 0 && parseInt(retenedor[0]?.value, 10) > materialsValidate.RETENEDOR.maxQuantity) {
            setAlertValidationRetenedor(`La cantidad en ${retenedor[0]?.name} debe ser como máximo ${materialsValidate.RETENEDOR.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationRetenedor('')
        }
        if (conectorSc?.length > 0 && parseInt(conectorSc[0]?.value, 10) > materialsValidate.CONECTOR_SC.maxQuantity) {
            setAlertValidationConectorSc(`La cantidad en ${conectorSc[0]?.name} debe ser como máximo ${materialsValidate.CONECTOR_SC.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationConectorSc('')
        }
        if (adaptadorMecanico?.length > 0 && parseInt(adaptadorMecanico[0]?.value, 10) > materialsValidate.ADAPTADOR_MECANICO.maxQuantity) {
            setAlertValidationAdaptadorMecanico(`La cantidad en ${adaptadorMecanico[0]?.name} debe ser como máximo ${materialsValidate.ADAPTADOR_MECANICO.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationAdaptadorMecanico('')
        }
        if (roseta?.length > 0 && parseInt(roseta[0]?.value, 10) > materialsValidate.ROSETA_OPTICA.maxQuantity) {
            setAlertValidationRoseta(`La cantidad en ${roseta[0]?.name} debe ser como máximo ${materialsValidate.ROSETA_OPTICA.maxQuantity}`)
            countErrors += 1;
        } else {
            setAlertValidationRoseta('')
        }
        if (cintaMetalica?.length > 0 || hebilla?.length > 0 || ojal?.length > 0) {
            if (cintaMetalica?.length > 0 && hebilla?.length > 0 && ojal?.length > 0) {
                setAlertValidationCintas('')
            } else {
                setAlertValidationCintas(`Los campos Cinta Metalica Abrazadera (mts), Ojal de acero Galvanizado (unid) y Hebilla Abrazadera de Fijacion (Unid) todos deben tener valores si al menos uno de ellos tiene un valor`)
                countErrors += 1;
            }
        }
        const filterSToSave = state.serialesToSave?.filter(serial => serial.tipo === 'ont')
        // if ((serviceSelected?.id !== servicesId.BASICA1P || serviceSelected?.id !== servicesId.BASICA2P) &&
        //     (state.serialOnt.length > 0 || filterSToSave?.length > 0)) {
        //     setAlertValidationSerialOnt('')
        // } else {
        //     setAlertValidationSerialOnt(`Debe seleccionar un serial ONT al tener seleccionado ${serviceSelected?.name}`)
        //     countErrors += 1;
        // }
        if ((serviceSelected?.id === servicesId.SOLOFLOW) &&
            state.serialesFlow.length === 0) {
            setAlertValidationSerialFlow(`Debe seleccionar al menos un serial FLOW al tener seleccionado ${serviceSelected?.name}`)
            countErrors += 1;
        } else {
            setAlertValidationSerialFlow('')
        }
        if (utp?.length > 0 && rj45?.length === 0) {
            setAlertValidationCintas(`Para tener valor en Cable UTP Cat 5e (mts) debe tener cargado tambien al menos una unidad en Fichas RJ45`)
            countErrors += 1;
        } else {
            setAlertValidationCintas('')
        }

        return countErrors > 0 ? false : true;
    }

    const saveMaterials = () => {
        const validateResult = validations();

        if (validateResult) {
            let serialsOnt = state.serialOnt;
            let serialsFlow = state.serialesFlow;
            if (isMudanza && state.serialesToSave?.length > 0) {
                state.serialesToSave?.map(serial => {
                    if (serial.tipo === 'ont') {
                        serialsOnt.push(serial.id)
                        return setState(prevState => ({ ...prevState }))
                    } else {
                        serialsFlow.push(serial.id)
                        return setState(prevState => ({ ...prevState }))
                    }
                })
            }

            let dataToSave = {
                materials: state.materialsToSave,
                serialOnt: serialsOnt,
                serialesFlow: serialsFlow,
                ontRetirado: state.ontRetirado,
                decoRetirado: state.decoRetirado,
                ontBodega: state.ontBodega,
                flowBodega: state.flowBodega,
                controles: state.controles,
                serialesConfigurados: state.serialesConfigurados,
                comments: state.comments,
            };
            setIsOnSave(true)

            setNewCuadrillas();
            onSave(dataToSave);

            state.materialsToSave.forEach(mate => {
                const filterMaterialsByCuadrilla = materialsCuadrilla.filter(
                    cuadrilla => cuadrilla.idMaterial === parseInt(mate.id, 10));

                if (filterMaterialsByCuadrilla.length > 0) {
                    filterMaterialsByCuadrilla.forEach(matxcuad => {
                        const quantityStock = matxcuad.cantidad - parseInt(mate.value, 10);
                        return (
                            update(ref(database, 'materialsxcuadrilla/' + matxcuad.idCuadrilla + 'c' + matxcuad.idMaterial),
                                { cantidad: quantityStock })
                                .then(() => {
                                    console.log('ok update materialsxcuadrilla')
                                })
                                .catch((e) => {
                                    setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true })
                                })
                        )
                    })
                } else {
                    const idCuadrilla = parseInt(cuadrillaSelected.id, 10);
                    const idMaterial = parseInt(mate.id, 10);
                    const dataToStock = {
                        cantidad: -parseInt(mate.value, 10),
                        idCuadrilla,
                        idMaterial
                    }
                    set(ref(database, 'materialsxcuadrilla/' + idCuadrilla + 'c' + idMaterial), dataToStock)
                        .then(() => console.log('ok materialsxcuadrilla'))
                        .catch(() => setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true }))
                }

            });

            update(ref(database, 'cuadrillas/' + cuadrilla.id), cuadrilla)
                .then(() => {
                    console.log('ok cuadrilla')
                    setClearForm();

                })
                .catch((e) => {
                    setSnackbarInfo({ text: 'Ocurrió un error al guardar la OT, intente nuevamente', type: 'error', open: true })
                    console.log(e);
                });




            //             const cuadrillaRef = ref(database, 'cuadrillas/' + cuadrilla.id);
            // const materialsxcuadrillaRefs = [];

            // state.materialsToSave.forEach(mate => {
            //     const filterMaterialsByCuadrilla = materialsCuadrilla.filter(
            //         cuadrilla => cuadrilla.idMaterial === parseInt(mate.id, 10)
            //     );

            //     if (filterMaterialsByCuadrilla.length > 0) {
            //         filterMaterialsByCuadrilla.forEach(matxcuad => {
            //             const quantityStock = matxcuad.cantidad - parseInt(mate.value, 10);
            //             const materialxcuadrillaRef = ref(database, 'materialsxcuadrilla/' + matxcuad.idCuadrilla + 'c' + matxcuad.idMaterial);
            //             materialsxcuadrillaRefs.push({ ref: materialxcuadrillaRef, data: { cantidad: quantityStock } });
            //         });
            //     } else {
            //         const idCuadrilla = parseInt(cuadrillaSelected.id, 10);
            //         const idMaterial = parseInt(mate.id, 10);
            //         const materialxcuadrillaRef = ref(database, 'materialsxcuadrilla/' + idCuadrilla + 'c' + idMaterial);
            //         materialsxcuadrillaRefs.push({ ref: materialxcuadrillaRef, data: { cantidad: -parseInt(mate.value, 10), idCuadrilla, idMaterial } });
            //     }
            // });

            // runTransaction(cuadrillaRef, (cuadrillaData) => {
            //     // Realiza las operaciones dentro de la transacción para cuadrillaData

            //     materialsxcuadrillaRefs.forEach(({ ref, data }) => {
            //         update(ref, data);
            //     });

            //     update(cuadrillaRef, cuadrilla)
            //     // cuadrillaData.lastUpdate = Date.now(); // Puedes realizar otras operaciones en cuadrillaData según sea necesario

            //     return cuadrillaData;
            // })
            //     .then(() => {
            //         console.log('Transacción exitosa');
            //         setTimeout(() => {
            //             setClearForm();
            //         }, 500);
            //         onSave(dataToSave);
            //     })
            //     .catch((error) => {
            //         console.error('Ocurrió un error durante la transacción:', error);
            //         setSnackbarInfo({ text: 'Ocurrió un error al guardar la OT, intente nuevamente', type: 'error', open: true });
            //     });

        }
    }

    const setClearForm = () => {
        setState({
            idOt: id,
            materials: [],
            materialsToSave: [],
            serialOnt: [],
            serialesFlow: [],
            ontRetirado: [],
            decoRetirado: [],
            serialesToSave: [],
            ontBodega: [],
            flowBodega: [],
            controles: [],
            serialesConfigurados: {},
            comments: '',
            submitted: true,
        })
        setSerialConfigList([
            <TextField
                id="outlined-number"
                label="Serial equipo configurado"
                type="number"
                onWheel={(e) => e.target.blur()}
                className="text-form__serial"
                onChange={(e) => onChangeValues(e, 0)}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{ marginBottom: '20px', marginRight: '10px' }}
            />
        ]);
        setMaterialsCuadrilla([]);
    }

    const onChangeTipoSerial = (e, index) => {
        e.preventDefault();
        const value = e.target.value;
        if (state?.serialesToSave[index]) {
            state.serialesToSave[index].tipo = value
        } else {
            const newTipo = { tipo: value, index: index }
            state.serialesToSave[index] = newTipo
        }
        setState(prevState => ({ ...prevState }))
    }

    const onChangeManualSerial = (e, index) => {
        const value = e.target.value;
        const name = e.target.name;
        const keys = Object.keys(state.serialesToSave);
        if (state?.serialesToSave[index]) {
            state.serialesToSave[index][name] = value
        } else {
            const newValue = { id: value, index: index }
            state.serialesToSave[index] = newValue
        }
        setState(prevState => ({ ...prevState }))

        if (e.target.value === '') {
            let materialNewArray = [...state.serialesToSave]
            const keyService = keys.filter(key => state.serialesToSave[key].index === index);
            materialNewArray.splice(parseInt(keyService[0], 10), 1);
            setState(prevState => ({ ...prevState, serialesToSave: materialNewArray }));
        }
    }

    const bodegaSerialOnt = bodega?.seriales?.filter(serial => serial.tipo === 'ont') || [];
    const bodegaSerialFlow = bodega?.seriales?.filter(serial => serial.tipo === 'flow') || [];
    const cuadrillaSerialOnt = cuadrilla?.seriales?.filter(serial => serial.tipo === 'ont') || [];
    const cuadrillaSerialFlow = cuadrilla?.seriales?.filter(serial => serial.tipo === 'flow') || [];

    const rows = [];
    if (isMudanza) {
        for (let i = 0; i < 10; i++) {
            const serialCharge = state.serialesToSave[i] || { tipo: '', observaciones: '', id: '' };
            rows.push(
                <InputSerials
                    index={i}
                    serialCharge={serialCharge}
                    onChangeTipoSerial={onChangeTipoSerial}
                    onChangeValues={onChangeManualSerial}
                    observations={false}
                />);
        }
    }

    const onAcceptSerial = (e) => {
        e.preventDefault();
        setOpenModalAdd(false);
    }

    return (
        <Container component="main">
            <>
                <Typography component="h2" variant="h6" sx={{ color: '#565e5e', marginBottom: '20px' }}>
                    Cuadrilla: <b>{cuadrillaSelected?.numero}</b>
                </Typography>
                <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            // multiple
                            id="checkboxes-tags-demo"
                            options={cuadrillaSerialOnt}
                            disablePortal
                            disabled={state.ontBodega?.length > 0}
                            onChange={(e, serials) => onChangeSerialOnt(e, serials)}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Serial ONT" placeholder="Serial ONT" />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={cuadrillaSerialFlow}
                            disabled={data?.services[0].id === servicesId.BASICA1P}
                            disableCloseOnSelect
                            onChange={(e, serials) => onChangeSeriales(e, serials, 'serialesFlow')}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Serial FLOW" placeholder="Serial FLOW" />
                            )}
                        />
                    </FormControl>
                </Stack>
                {isMudanza &&
                    <Stack>
                        <Button
                            variant="outlined"
                            endIcon={<AddCircleOutlinedIcon />}
                            onClick={(event) => {
                                event.preventDefault();
                                setOpenModalAdd(true)
                            }}
                            sx={{ width: '50%', margin: '0 auto 35px' }}
                        >
                            Agregar seriales manualmente
                        </Button>
                        <Dialog open={openModalAdd} onClose={() => setOpenModalAdd(false)}>
                            <DialogTitle>Agregar seriales</DialogTitle>
                            <DialogContent>
                                {rows}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={(e) => onAcceptSerial(e)}>Aceptar</Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                }
                <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
                    <TextField
                        id="outlined-number"
                        label="Control deco SAGEMCOM"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="text-form__main-materials"
                        onChange={(e) => onChangeControles(e, 0, 'sagemcom')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ margin: '0, 10px', flexBasis: '25%' }}
                    />
                    <TextField
                        id="outlined-number"
                        label="Control deco SAGEMCOM Reac."
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="text-form__main-materials"
                        onChange={(e) => onChangeControles(e, 1, 'sagemcom reacondicionado')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ margin: '0, 10px', flexBasis: '25%' }}
                    />
                    <TextField
                        id="outlined-number"
                        label="Control deco Android"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="text-form__main-materials"
                        onChange={(e) => onChangeControles(e, 2, 'android')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ margin: '0, 10px', flexBasis: '25%' }}
                    />
                    <TextField
                        id="outlined-number"
                        label="Control deco Android Reac."
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="text-form__main-materials"
                        onChange={(e) => onChangeControles(e, 3, 'android reacondicionado')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ margin: '0, 10px', flexBasis: '25%' }}
                    />
                </Stack>
                {state.serialesFlow?.length > 0 && state.controles?.length === 0 &&
                    <MuiAlert severity="warning">
                        No se colocó ningún valor para controles y se seleccionó al menos un serial FLOW
                    </MuiAlert>
                }
                <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={bodegaSerialOnt}
                            disableCloseOnSelect
                            onChange={(e, serials) => onChangeSeriales(e, serials, 'ontRetirado')}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="ONT retirado" placeholder="ONT retirado" />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={bodegaSerialFlow}
                            disableCloseOnSelect
                            onChange={(e, serials) => onChangeSeriales(e, serials, 'decoRetirado')}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Deco retirado" placeholder="Deco retirado" />
                            )}
                        />
                    </FormControl>
                </Stack>
                {serialConfigList}
                <IconButton aria-label="add" onClick={(e) => onAddBtnClick(e, 'serial')}>
                    <AddCircleOutlinedIcon />
                </IconButton>
                <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            options={bodegaSerialOnt}
                            disableCloseOnSelect
                            disabled={state.serialOnt?.length > 0}
                            onChange={(e, serials) => onChangeSeriales(e, serials, 'ontBodega')}
                            getOptionLabel={(option) => option.id}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="ONT instalado bodega" placeholder="ONT instalado bodega" />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '50%' }}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={bodegaSerialFlow}
                            disableCloseOnSelect
                            onChange={(e, serials) => onChangeSeriales(e, serials, 'flowBodega')}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.id}
                                </li>
                            )}
                            style={{ width: '100%', margin: '10px 0' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Flow instalado bodega" placeholder="Flow instalado bodega" />
                            )}
                        />
                    </FormControl>
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'initial' }} className="box-container">
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup sx={{ flexDirection: 'initial' }}>
                            {state?.materials?.map(material => {
                                const stock = materialsCuadrilla.filter(mater => mater.idMaterial === material?.id)[0]?.cantidad
                                const filterValue = state?.materialsToSave?.filter(mater => mater?.id === material?.id)[0]?.value;
                                return (
                                    material?.visible &&
                                    <TextField
                                        id="outlined-number"
                                        label={material?.name}
                                        value={filterValue}
                                        type="number"
                                        placeholder={`Stock: ${stock}`}
                                        onWheel={(e) => e.target.blur()}
                                        className="text-form__material"
                                        onChange={(e) => changeInput(e, material?.id, material?.name)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{ flexBasis: '18%', margin: '7px 5px 7px 0' }}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
                <Stack direction="row" sx={{ marginTop: '40px', marginBottom: '20px' }}>
                    <TextField
                        id="outlined-multiline-flexible"
                        required
                        label="Comentarios (obligatorio)"
                        onChange={onChangeComments}
                        multiline
                        sx={{ width: '100%' }}
                        maxRows={4}
                    // value={state.comments}
                    />
                </Stack>
                {alertValidationAdaptadorMecanico !== '' && <MuiAlert severity="error">{alertValidationAdaptadorMecanico}</MuiAlert>}
                {alertValidationRoseta !== '' && <MuiAlert severity="error">{alertValidationRoseta}</MuiAlert>}
                {alertValidationConectorSc !== '' && <MuiAlert severity="error">{alertValidationConectorSc}</MuiAlert>}
                {alertValidationRetenedor !== '' && <MuiAlert severity="error">{alertValidationRetenedor}</MuiAlert>}
                {alertValidationDropExterno !== '' && <MuiAlert severity="error">{alertValidationDropExterno}</MuiAlert>}
                {alertValidationCintas !== '' && <MuiAlert severity="error">{alertValidationCintas}</MuiAlert>}
                {alertValidationPreco50 !== '' && <MuiAlert severity="error">{alertValidationPreco50}</MuiAlert>}
                {alertValidationPreco100 !== '' && <MuiAlert severity="error">{alertValidationPreco100}</MuiAlert>}
                {alertValidationPreco150 !== '' && <MuiAlert severity="error">{alertValidationPreco150}</MuiAlert>}
                {alertValidationPreco220 !== '' && <MuiAlert severity="error">{alertValidationPreco220}</MuiAlert>}
                {alertValidationPreco300 !== '' && <MuiAlert severity="error">{alertValidationPreco300}</MuiAlert>}
                {alertValidationSerialOnt !== '' && <MuiAlert severity="error">{alertValidationSerialOnt}</MuiAlert>}
                {alertValidationSerialFlow !== '' && <MuiAlert severity="error">{alertValidationSerialFlow}</MuiAlert>}

                {isOnSave ? (
                    <LoadingButton
                        fullWidth
                        loading
                        variant="contained"
                        color="primary"
                        className="button__save"
                        sx={{ margin: '15px 0', width: '50%' }}
                    >
                        Aceptar
                    </LoadingButton>
                ) : (
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="button__save"
                        onClick={saveMaterials}
                        sx={{ margin: '15px 0', width: '50%' }}
                        disabled={state.comments === ''}
                    >
                        Aceptar
                    </Button>
                )}
            </>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default AddMaterials