import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../firebase";
import { ref, query, orderByChild, equalTo, onValue, update } from "firebase/database";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

function EditCuadrilla() {
    let { id } = useParams();
    const [state, setState] = useState({
        id: id,
        numero: '',
        codigo: '',
        submitted: false,
    });
    const [keyPrice, setKeyPrice] = useState('');

    useEffect(() => {
        const topUserPostsRef = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(parseInt(state.id, 10)));
        onValue(topUserPostsRef, (snapshot) => {
            setValues(Object.values(snapshot.val()));
        }, {
            onlyOnce: true
        });
    }, []);

    const setValues = (values) => {
        const keyObject = Object.keys(values);
        setKeyPrice(keyObject[0]);
        if (values instanceof Array) {
            values.forEach(val => {
                setState(prevState => ({
                    ...prevState,
                    id: val.id,
                    numero: val.numero,
                    codigo: val?.codigo,
                }));
            })
        } else {
            const valuesProf = values[keyObject]
            setState(prevState => ({
                ...prevState,
                id: valuesProf.id,
                numero: valuesProf.numero,
                codigo: valuesProf?.codigo,
            }));
        }
    }

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const updateCuadrilla = () => {
        let data = {
            id: state.id,
            numero: state.numero,
            codigo: state.codigo,
        };

        update(ref(database, 'cuadrillas/' + state.id), data)
            .then(() => {
                setState({
                    submitted: true,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const newProduct = () => {
        setState({
            // id: id,
            numero: '',

            submitted: false,
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            {state.submitted ? (
                <div>
                    <Typography variant="h4">Cuadrilla editada correctamente!</Typography>
                    <Button href="/cuadrillas"> Ir a listado </Button>
                </div>
            ) : (
                <div className="form-container">
                    <Typography component="h1" variant="h5">
                        Editar Cuadrilla
                    </Typography>
                    <div className="order-container">
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="numero"
                                    label="Numero / Nombre"
                                    value={state.numero}
                                    name="numero"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="codigo"
                                    label="Código"
                                    value={state.codigo}
                                    name="codigo"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="button__save"
                            onClick={updateCuadrilla}
                        >
                            Aceptar
                        </Button>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default EditCuadrilla;
